import React from 'react';
import dayjs from 'dayjs';
import styled from '@emotion/styled';

import { formatCurrency } from 'utils/rifmFormatters';
import { getListingAddress } from 'utils/listing';
import AccordionTableItem from 'components/AccordionTableItem';
import { Icon } from 'components';
import ListingPhotoAndAddress from './ListingPhotoAndAddress';

interface ItemTypeTagProps extends React.HTMLAttributes<HTMLSpanElement> {
  type: 'sell' | 'buy';
}

const ItemTypeTag: React.FC<ItemTypeTagProps> = ({ type, ...props }) => {
  return (
    <StyledItemTypeTag {...props} className={type}>
      {'R'}
    </StyledItemTypeTag>
  );
};
interface ListingItemTerminatedProps {
  data: Listing;
}



const ListingItemTerminated: React.FC<ListingItemTerminatedProps> = ({ data }) => {
  return (
    <AccordionTableItem
      fields={[
        {
          key: 'address',
          label: 'Address',
          value: (
            <>
              <ListingPhotoAndAddress src={data.images?.[0]} address={getListingAddress(data)} />
              <ItemTypeTag type={data.type.toLowerCase() as any} title={data.type} />
            </>
          )
        },
        {
          key: 'type',
          label: 'Type',
          value: data.propertyType || '-'
        },{
          key: 'date',
          label: 'Listed',
          value: data.publishDate ? dayjs.unix(data.publishDate).format('MM/DD/YYYY') : '-'
        },
      ]}
    />
  );
};

export default ListingItemTerminated;

const StyledItemTypeTag = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: #f1f1f1;
  color: ${props => props.theme.colors.red};
  border-radius: 50%;
  flex-shrink: 0;
  text-align: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  margin: 0 4px;

  &.buy {
    background: #cdcdcd;
    color: #000;
  }
`;
