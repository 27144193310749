import React from 'react';
import dayjs from 'dayjs';
import styled from '@emotion/styled';

import { formatCurrency } from 'utils/rifmFormatters';
import { getListingAddress } from 'utils/listing';
import AccordionTableItem from 'components/AccordionTableItem';
import { Icon } from 'components';
import ListingPhotoAndAddress from './ListingPhotoAndAddress';

interface ItemTypeTagProps extends React.HTMLAttributes<HTMLSpanElement> {
  type: 'sell' | 'buy';
}

const ItemTypeTag: React.FC<ItemTypeTagProps> = ({ type, ...props }) => {
  return (
    <StyledItemTypeTag {...props} className={type}>
      {type === 'sell' ? 'S' : 'P'}
    </StyledItemTypeTag>
  );
};
interface ListingItemClosedProps {
  data: Listing;
}

const ListingItemClosed: React.FC<ListingItemClosedProps> = ({ data }) => {
  return (
    <AccordionTableItem
      fields={[
        {
          key: 'address',
          label: 'Address',
          value: (
            <>
              <ListingPhotoAndAddress src={data.images?.[0]} address={getListingAddress(data)} />
              <ItemTypeTag type={data.type.toLowerCase() as any} title={data.type} />
            </>
          )
        },
        {
          key: 'type',
          label: 'Type',
          value: data.propertyType || '-'
        },
        {
          key: 'closingDate',
          label: 'Closing Date',
          value: data.closingDate ? dayjs.unix(data.closingDate).format('MM/DD/YYYY') : '-'
        },
        {
          key: 'contract_price',
          label: 'Sale Price',
          value: data.contractPrice ? formatCurrency(data.contractPrice) : '-'
        },
        {
          key: 'earnestMoney1',
          label: 'Earnest Money',
          value: data.earnestMoney ? formatCurrency(data.earnestMoney) : '-'
        },
        {
          key: 'financials',
          label: 'Financials',
          value: data.financials || '-'
        },
        {
          key: 'post_closing_escrows',
          label: 'Post-closing escrows',
          value: data.postClosingEscrows ? formatCurrency(data.postClosingEscrows) : '-'
        },
        {
          key: 'commisions',
          label: 'Commisions',
          value: data.fullcommissions ? formatCurrency((data.fullcommissions.additionalSaleCommission ? data.fullcommissions.additionalSaleCommission : 0) - (data.fullcommissions.additionalCoopCommission ? data.fullcommissions.additionalCoopCommission : 0) + (data.fullcommissions.marketingFee ? data.fullcommissions.marketingFee : 0) + (data.fullcommissions.mlsFee ? data.fullcommissions.mlsFee : 0) + (((data.fullcommissions.saleCommission ? data.fullcommissions.saleCommission : 0) - (data.fullcommissions.saleCoopCommission && data.dualListing == 0 ? data.fullcommissions.saleCoopCommission : 0)) * data.currentPrice / 100)) : '-'
        },
        {
          key: 'actions',
          label: '',
          value: data.url && false && (
            <a className="table-header-link" href={data.url} target="_blank" rel="noreferrer">
              <span>View page</span>&nbsp;
              <Icon name="arrow" size={12} />
            </a>
          )
        }
      ]}
    />
  );
};

export default ListingItemClosed;

const StyledItemTypeTag = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: #f1f1f1;
  color: ${props => props.theme.colors.red};
  border-radius: 50%;
  flex-shrink: 0;
  text-align: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  margin: 0 4px;

  &.buy {
    background: #cdcdcd;
    color: #000;
  }
`;
