import React, { useMemo } from 'react';
import styled from '@emotion/styled';
// import Image from 'components/Image';
import clsx from 'clsx';
import { formatCurrency } from 'utils/rifmFormatters';
import { getTimeDiffInDays } from 'utils/date';
// import { getListingImageProps } from 'utils/listing';
import dayjs from 'dayjs';

interface CardItemProps extends React.HTMLAttributes<HTMLDivElement> {
  data: Listing;
  type: DashboardTabKey;
  onWorkspaceOpen?: (listingId: string) => void;
}

const CardItem: React.FC<CardItemProps> = ({ data, className, type, onWorkspaceOpen }) => {
  const fullAddress = [data.address1, data.address2].filter(Boolean).join(', ');

  const info = useMemo(() => {
    switch (type) {
      case 'sales':
        return (
          <>
            <div className="listing-card__info">
              <span>Listing Price</span>
              {data.currentPrice ? (
                <span className="price">{formatCurrency(data.currentPrice)}</span>
              ) : (
                '---'
              )}
            </div>
            <div className="listing-card__info">
              <span>Market Days</span>
              {getTimeDiffInDays(data.publishDate) || '---'}
            </div>
            <div className="listing-card__info">
              <span>Offers</span>
              {data.offersCount || '---'}
            </div>
          </>
        );
      case 'purchases': {
        return (
          <>
            <div className="listing-card__info">
              <span>Listing Price</span>
              {data.currentPrice ? (
                <span className="price">{formatCurrency(data.currentPrice)}</span>
              ) : (
                '---'
              )}
            </div>
            <div className="listing-card__info">
              <span>Offer Amount</span>
              {data.lastOfferAmount ? (
                <span className="price">{formatCurrency(data.lastOfferAmount)}</span>
              ) : (
                '---'
              )}
            </div>
            <div className="listing-card__info">
              <span>Pending</span>
              {`${getTimeDiffInDays(data.publishDate)} days` || '---'}
            </div>
          </>
        );
      }
      case 'underContract':
        return (
          <>
            <div className="listing-card__info">
              <span>Listing Price</span>
              {data.currentPrice ? (
                <span className="price">{formatCurrency(data.currentPrice)}</span>
              ) : (
                '---'
              )}
            </div>
            <div className="listing-card__info">
              <span>Earnest Money</span>
              {data.earnestMoney ? formatCurrency(data.earnestMoney) : '---'}
            </div>
            <div className="listing-card__info">
              <span>Closing Date</span>
              {data.closingDate ? dayjs.unix(data.closingDate).format('MM/DD/YYYY') : '---'}
            </div>
          </>
        );
      case 'closed':
        return (
          <>
            <div className="listing-card__info">
              <span>Purchase Price</span>
              {data.contractPrice ? (
                <span className="price">{formatCurrency(data.contractPrice)}</span>
              ) : (
                '---'
              )}
            </div>
            <div className="listing-card__info">
              <span>Commission</span>
              {data.commissions ? formatCurrency(data.commissions) : '---'}
            </div>
            <div className="listing-card__info">
              <span>Closed</span>
              {data.closingDate ? dayjs.unix(data.closingDate).format('MM/DD/YYYY') : '---'}
            </div>
          </>
        );
        case 'RentalActive':
          return (
            <>
              <div className="listing-card__info">
                <span>Listing Price</span>
                {data.currentPrice ? (
                  <span className="price">{formatCurrency(data.currentPrice)}</span>
                ) : (
                  '---'
                )}
              </div>
              <div className="listing-card__info">
                <span>Market Days</span>
                {getTimeDiffInDays(data.publishDate) || '---'}
              </div>
              <div className="listing-card__info">
                <span>Offers</span>
                {data.offersCount || '---'}
              </div>
            </>
          );
          case 'RentalActive':
            return (
              <>
                <div className="listing-card__info">
                  <span>Purchase Price</span>
                  {data.contractPrice ? (
                    <span className="price">{formatCurrency(data.contractPrice)}</span>
                  ) : (
                    '---'
                  )}
                </div>
                <div className="listing-card__info">
                  <span>Commission</span>
                  {data.commissions ? formatCurrency(data.commissions) : '---'}
                </div>
                <div className="listing-card__info">
                  <span>Closed</span>
                  {data.closingDate ? dayjs.unix(data.closingDate).format('MM/DD/YYYY') : '---'}
                </div>
              </>
            );
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  // const firstImageUrl = data.images?.[0];
  // const imageProps = getListingImageProps(firstImageUrl);

  return (
    <StyledCardItem
      className={clsx('listing-card', className)}
      onClick={() => onWorkspaceOpen?.(data.listingId)}>
      {/* <Image
        className="listing-card__photo"
        onClick={() => onWorkspaceOpen?.(data.listingId)}
        size="400x300"
        {...imageProps}
      /> */}
      <h3 className="listing-card__address" title={fullAddress}>
        {fullAddress}
      </h3>
      <div>{data.city}</div>
      <div className="listing-card__type">{data.propertyType || '---'}</div>
      <hr />
      <div className="listing-card__info">
        <span>MLS #</span>
        {data.listingId || '---'}
      </div>
      {info}
    </StyledCardItem>
  );
};

export default React.memo(CardItem);

const StyledCardItem = styled.div`
  width: 100%;
  max-width: 360px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  padding: 16px;
  margin: auto;
  cursor: pointer;

  .listing-card {
    &__photo {
      width: calc(100% + 32px);
      height: 65px;
      object-fit: cover;
      margin: -16px -16px 0 -16px;
      cursor: pointer;
    }
    &__address {
      margin: 12px 0 4px 0;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__type {
      color: #8b8b8b;
      margin-top: 8px;
    }
    &__info {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      line-height: 16px;
      margin-top: 12px;

      .price {
        color: ${props => props.theme.colors.green};
      }
    }
  }

  hr {
    margin: 12px -16px;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 10px;

    .listing-card__photo {
      margin: -10px -10px 0 -10px;
      width: calc(100% + 20px);
    }

    hr {
      margin: 12px -10px;
    }
  }
`;
