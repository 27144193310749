/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Checkbox, Input, InputPlaces, SideMenu, SideMenuProps,Dropdown } from 'components';
import styled from '@emotion/styled';
import ReactInputMask from 'react-input-mask';
import {  DropdownOption } from '@xchange/uikit';
import { REGEXP } from 'consts';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { createListing, getListingOrders, getTeam,getServicingOrders } from './api';
import useAuthContext from 'hooks/useAuthContext';

const optionalStringWithSpace = (str?: string) => (str ? `${str} ` : '');

interface CreateNewListingProps extends SideMenuProps {
  onSuccess: () => any;
}

const CreateNewListing: React.FC<CreateNewListingProps> = ({
  onClose,
  onSuccess,
  open,
  ...props
}) => {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm();
  const [orders, setOrders] = useState<ListingOrder[]>();
  const [offerUsers, setListingUsers] = useState<DropdownOption[]>([]);
  const [selectedOfferUser, setSelectedListingUser] = useState("");
  const [notRental, setNotRental] = useState(Boolean(false));

  const { authData } = useAuthContext();

  const submit = async (form: CreateListingFormValues) => {
    Object.entries(form.seller!).forEach(
      ([key, value]) => value === '' && delete form.seller![key]
    );
    if (Object.keys(form.seller!).length === 0) delete form.seller;

    form.address = form.address1 + (" "+form.address2).trim() + ", " + form.city + ", " + form.state + " " + form.zip;

    try {
      await createListing(form);
      notify('Listing created');
      if(notRental){
        window.location.reload(false);
      }
      onSuccess();
      onClose?.();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const getTeamMembers = async () => { 
    const teamMembers = await getTeam();
  
    const usersDropdown : DropdownOption[] = Object.values(teamMembers["team"]).map(row => ({
      value: row.user_id.toString(),
      text: row.name + (row.is_teamlead == 1 ? " (Team Lead)" : "")
    }));
    
    setListingUsers(usersDropdown);
    setSelectedListingUser(teamMembers["me"].toString());
   
  }

  const handleAddressChange = place => {
    if (!place) return;
    const streetNumber = place.address_components?.find(item =>
      item.types.includes('street_number')
    )?.long_name;
    const route = place.address_components?.find(item => item.types.includes('route'))?.long_name;
    const city = place.address_components?.find(item => item.types.includes('locality'))?.long_name;
    const state = place.address_components?.find(item =>
      item.types.includes('administrative_area_level_1')
    )?.short_name;
    const zip = place.address_components?.find(item => item.types.includes('postal_code'))
      ?.short_name;
    if (route) setValue('address1', `${optionalStringWithSpace(streetNumber)}${route}`);
    if (city) setValue('city', city);
    if (state) setValue('state', state);
    if (zip) setValue('zip', zip);
  };

  const checkKeyDown = e => {
    if (e.key === 'Enter') e.preventDefault();
  };

  const handleChangeMainUser = (newUser: string) => {
    setSelectedListingUser(newUser);
  };

  useEffect(() => {
    if (!open || orders) return;
    (async () => {
      try {
        if(authData.superAdmin){
          var orders = await getServicingOrders();
        }else{
          var orders = await getListingOrders();
        }
        
        setOrders(orders);
        getTeamMembers();

      } catch (err) {
        notify(tryGetFirstError(err));
      }
    })();
  }, [open, orders]);

  return (
    <StyledSideMenu title="Start Listing" open={open} onClose={onClose} {...props}>
      <hr />
      <form onKeyDown={checkKeyDown} onSubmit={handleSubmit(submit)}>
        <h4 className="title">Property Information</h4>
        <div className="process-form__input-block">
        <Controller
          control={control}
          name="isRental"
          defaultValue={notRental}
          render={({ field: { onChange, ...field }, formState: { errors } }) => (
            <Checkbox
              label={"Is this a rental property?"}
              onChange={e => {
                onChange(e.target.checked);
                setNotRental(e.target.checked);
                }
              }
              className="sale-side__checkbox"
              {...field}
            />
          )}
        />
        </div>
        <div className="process-form__input-block">
          <Controller
            control={control}
            name="address"
            defaultValue=""
            rules={{
              required: 'Required',
              maxLength: {
                value: 100,
                message: `Address can not exceed 100 characters`
              },
              pattern: {
                value: REGEXP.ADDRESS,
                message: 'Invalid Address'
              }
            }}
            render={({ field: { onChange, ...field } }) => (
              <InputPlaces
                label={
                  <>
                    Enter address<span className="red-asterisk">*</span>
                  </>
                }
                placeholder="Enter address"
                error={errors.address?.message}
                autoComplete="off"
                onChange={onChange}
                onPlaceChange={(query, place) => {
                  handleAddressChange(place);
                  onChange(query);
                }}
                {...field}
              />
            )}
          />
        </div>
        <div className="input-group">
          <Input
            {...register('address1', {
              required: 'Required',
              maxLength: { value: 100, message: `Address can not exceed 100 characters` },
              pattern: { value: REGEXP.ADDRESS, message: 'Invalid Address' }
            })}
            error={errors.address1?.message}
            label="Address line 1"
            placeholder="Enter address 1"
          />
          <Input {...register('address2')} label="Address line 2" placeholder="Enter address 2" />
        </div>
        <div className="input-group">
          <Input
            {...register('state', { required: 'Required' })}
            error={errors.state?.message}
            label="State"
            placeholder="Enter State"
          />
          <Input
            {...register('city', { required: 'Required' })}
            error={errors.city?.message}
            label="City"
            placeholder="Enter City"
          />
        </div>
        <div className="input-group">
          <Input
            {...register('zip', {
              required: 'Required',
              pattern: { value: REGEXP.ZIP_CODE, message: 'Invalid ZIP code' }
            })}
            error={errors.zip?.message}
            label="ZIP Code"
            placeholder="Enter ZIP Code"
          />
          <div />
        </div>
      
        <h4 className="title">Select user</h4>
        {(selectedOfferUser)  && (
          <>
            <Controller
                control={control}
                rules={{ required: 'Required' }}
                name="user_id"
                defaultValue={selectedOfferUser}
                render={({ field: { onChange, ...field } }) => (
                  <Dropdown
                    options={offerUsers}
                    onChange={value => {
                      handleChangeMainUser(value);
                      onChange(value);
                    }}
                    label="Assign to User*"
                    placeholder="Select"
                    error={errors.user_id?.message} 
                    search
                    {...field}
                  />
                )}
              />
          </>
        )}
    
        <h4 className="title">Seller Information</h4>
        <div className="input-group">
          <Input
            {...register('seller.name', {
              required: 'Required',
              pattern: { value: REGEXP.NAME, message: 'Invalid name' }
            })}
            error={errors.seller?.name?.message}
            label="Name*"
            placeholder="Enter Name"
          />
          <Input
            {...register('seller.email', {
              required: 'Required',
              pattern: { value: REGEXP.EMAIL, message: 'Invalid email format' }
            })}
            error={errors.seller?.email?.message}
            label="Email*"
            placeholder="Enter Email"
          />
        </div>
        <div className="input-group">
          <Input
            {...register('seller.contact_person', {
              pattern: { value: REGEXP.NAME, message: 'Invalid name' }
            })}
            error={errors.seller?.contact_person?.message}
            label="Contact Person Name (for company seller)"
            placeholder="Enter Name"
          />
          <Controller
            control={control}
            name="seller.phone"
            rules={{
              required: 'Required',
              validate: value => !value.includes('_') || `Invalid phone`
            }}
            defaultValue=""
            render={({ field, formState: { errors } }) => (
              <Input
                className="deadline-form__input"
                as={ReactInputMask}
                mask="(999) 999-9999"
                type="tel"
                error={errors.seller?.phone?.message}
                label="Phone Number*"
                placeholder="Enter Phone Number"
                {...field}
              />
            )}
          />
         
        </div>
        <div className="form-buttons">
          <Button secondary type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Create
          </Button>
        </div>
        <hr />
        <h4 className="title">Task(s) for this Listing</h4>
        {orders?.map(item => (
          <Checkbox key={item.id} {...register('orders')} value={item.id} label={item.title} defaultChecked />
        ))}
      </form>
    </StyledSideMenu>
  );
};

export default CreateNewListing;

const StyledSideMenu = styled(SideMenu)`
  .side-menu {
    width: 700px;
  }

  h4.title {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  form {
    display: flex;
    flex-direction: column;

    .input {
      width: 100%;
      margin-bottom: 16px;

      .red-asterisk {
        color: ${props => props.theme.colors.red};
      }
    }
    .input-group {
      display: grid;
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
      grid-gap: 24px;
    }
    .checkbox {
      margin-bottom: 12px;
    }
    .form-buttons {
      display: flex;
      justify-content: flex-end;

      .button:nth-of-type(1) {
        margin-right: 8px;
      }
    }
  }
`;
